<template>
  <ui-fields-wrap
    ref="refWrap"
    :label="label"
    :filed-wrap-class="filedWrapClass"
    :label-two="labelTwo"
    :required="required"
    input-wrap-class="md:py-[13px] md:min-h-[60px] min-h-[40px] flex items-center"
    :input-id="inputId"
    :hint="hint"
    :has-value="hasValue"
    :disabled="disabled"
    :error="errorMessage"
    wrap-class="data-focus:rounded-b-none data-focus:border-input-active-border"
    @mousedown:control="openDropdown"
  >
    <template #default="{ inputId, inputClasses, onFocus, onBlur }">
      <v-select
        ref="selectRef"
        v-model="value"
        :input-id="inputId"
        v-bind="selectProps"
        :clearable="clearable"
        :class="[{ 'vs--has-value': hasValue }, inputClasses]"
        class="w-full"
        :disabled="disabled"
        :options="options"
        :multiple="multiple"
        :placeholder="placeholder"
        :reduce="reduce"
        :append-to-body="appendToBody"
        :get-option-label="getOptionLabel"
        :get-option-key="getOptionKey"
        :loading="loading"
        @update:model-value="$emit('select', $event)"
        @open="onFocus"
        @close="onBlur"
        @search="$emit('search', $event)"
      >
        <template #open-indicator="{ attributes }">
          <ui-icon
            v-bind="attributes"
            name="expand_more"
            class="w-5 h-5 text-xl"
          />
        </template>
        <template
          v-for="(_, slotName) in $slots"
          #[slotName]="slotData"
        >
          <slot
            :name="slotName"
            v-bind="slotData"
          />
        </template>
      </v-select>
    </template>
  </ui-fields-wrap>
</template>

<script setup lang="ts">
  import type { VueSelectProps } from 'vue-select';

  type OptionConsumer<T = any> = (option: any) => T;

  type Props = {
    options: (string | Keyable)[];
    multiple?: VueSelectProps['multiple'];
    reduce?: OptionConsumer;
    getOptionLabel?: OptionConsumer;
    getOptionKey?: OptionConsumer;
    appendToBody?: VueSelectProps['appendToBody'];
    loading?: VueSelectProps['loading'];
    clearable?: boolean;
    selectProps?: Partial<
      Omit<
        VueSelectProps,
        | 'options'
        | 'multiple'
        | 'reduce'
        | 'loading'
        | 'getOptionLabel'
        | 'getOptionKey'
        | 'placeholder'
        | 'value'
        | 'disabled'
        | 'inputId'
        | 'clearable'
      >
    >;
  } & Omit<BaseField, 'iconLeft' | 'iconRight' | 'onClickRightIcon' | 'onClickLeftIcon'>;
  defineEmits(['search', 'update:modelValue', 'select']);
  const props = withDefaults(defineProps<Props>(), {
    multiple: false,
    options: () => [],
    getOptionLabel: () => (option: any) => option?.name || option?.label || option?.title || option,
    reduce: () => (option: any) => option?.id || option?.value || option,
    selectProps: () => ({}),
    autofocus: false,
    clearable: false
  });

  const selectRef = ref();
  const refWrap = ref();

  const { value, errorMessage } = useField(() => props.name || '', undefined, {
    initialValue: props.modelValue,
    syncVModel: props.syncVModel,
    controlled: !props.enabledControlled,
    keepValueOnUnmount: props.keepValueOnUnmount
  });

  const hasValue = computed(() => {
    const val = isArray(value.value) ? value.value.length : value.value;
    return !!(selectRef.value?.search || val);
  });

  const openDropdown = (e: any = { preventDefault() {} }) => {
    if (!selectRef.value?.open) {
      nextTick(() => {
        refWrap.value?.onBlur();
      });
    }
    if (selectRef.value?.$el.contains(e.target)) {
      return;
    }
    selectRef.value?.toggleDropdown?.(e);
  };

  onMounted(async () => {
    await nextTick();
    if (props.autofocus) {
      openDropdown();
    }
  });
</script>

<style lang="scss" scoped>
  ::v-deep(.v-select) {
    --vs-border-width: 0;
    --vs-border-color: transparent;
    --vs-controls-color: var(--color-body-text);
    --vs-selected-bg: var(--color-input-bg);
    --vs-selected-border-color: var(--color-input-active-border);
    position: static;
    margin-bottom: -2px;
    @apply text-sm md:text-lg;

    @screen md {
      --vs-actions-padding: 0 2px;
    }

    &.vs--has-value {
      margin-left: -2px;
      margin-top: -2px;
      @screen md {
        margin-left: -6px;
        margin-top: -6px;
      }
    }

    .vs {
      &__selected {
        @apply text-input-active-text;
      }

      &__search,
      &__selected-options {
        @apply p-0 m-0;
      }

      &__search {
        @apply md:text-lg leading-tight text-sm;
        &::placeholder {
          color: #9ca3af;
        }
      }

      &__selected-options {
        min-height: 26px;
        @apply p-0 m-0;

        @screen md {
          min-height: 36px;
        }
      }

      &__actions {
        @apply text-input-text;
      }

      &__dropdown {
        &-menu {
          top: calc(100% + 1px);
          width: calc(100% + 2px);
          left: -1px;
          @apply border border-input-active-border rounded-b-xl py-0 bg-input-bg;
        }
        &-toggle {
          @apply pb-0;
        }
        &-option {
          @apply p-3.5 md:px-7 text-input-text hover:text-input-active-text border-b border-input-active-border/20 last:border-none;

          &--selected {
            color: rgb(var(--color-input-active-text)) !important;
          }

          &--highlight {
            @apply bg-transparent;
          }
        }
      }

      &__deselect {
        scale: 0.8;
      }
    }
  }

  .vs--single::v-deep(.vs__selected) {
    @apply -ml-1;
  }

  .vs--multiple {
    ::v-deep(.vs__selected) {
      @apply text-base;
    }

    ::v-deep(.vs__selected-options) {
      max-height: 200px;
      overflow: auto;
      .vs__selected {
        @apply text-input-text bg-input-active-border;
      }
    }
  }
</style>
